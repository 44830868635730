import PropTypes from 'prop-types';

import { Separator as SeparatorContainer} from "@/components/ui/separator";

function Separator({ orientation, className, ...props }) {
	return <SeparatorContainer 
                orientation={orientation} 
                className={className}
            />;
}

export default Separator;

Separator.propTypes = {
    orientation: PropTypes.string.isRequired,
    className: PropTypes.string,
};

Separator.defaultProps = {
    orientation: null,
    className: null,
}